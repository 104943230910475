  /**=====================
     14. Chat CSS Start
==========================**/
  .status-circle {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 4px;
      left: 44px;
      border-radius: 50%;
      border: 2px solid $white;
  }
  .user-image {
      float: left;
      width: 52px;
      height: 52px;
      margin-right: 5px;
      .about {
          float: left;
          margin-top: 5px;
          padding-left: 10px;
          .name {
              color: $dark-color;
              letter-spacing: 1px;
              font-weight: 500;
          }
      }
  }
  /**==private-chat==**/
  .left-sidebar-wrapper {
      padding: 20px;
      border-radius: 10px 0 0 10px;
      z-index: 1;
      border: 2px solid $white;
      border-right: unset;
      [data-theme="dark"] & {
          border: 2px solid var(--light-color);
          box-shadow: var(--card-box-shadow);
      }
      @media (max-width:767px) {
          border-radius: 10px;
      }
      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      * {
          scrollbar-width: auto;
          scrollbar-color: rgba($primary-color, 0.1);
      }
      /* Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
          width: 6px;
      }
      *::-webkit-scrollbar-track {
          background: transparent;
      }
      *::-webkit-scrollbar-thumb {
          background-color: rgba($primary-color, 0.1);
          border-radius: 10px;
          border: 3px solid transparent;
      }
      .left-sidebar-chat {
          .input-group {
              .input-group-text {
                  background-color: var(--light-gray);
                  padding-right: 0;
                  [data-theme="dark"] & {
                      background-color: var(--body-color);
                  }
                  svg {
                      width: 16px;
                      height: 16px;
                      [data-theme="dark"] & {
                          color: $white;
                      }
                  }
              }
              > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
              }
              .form-control {
                  border: none !important;
                  background-color: var(--light-gray);
                  [data-theme="dark"] & {
                      background-color: var(--body-color);
                  }
                  &:focus,
                  &:focus-visible {
                      border-color: var(--light-gray);
                      box-shadow: unset;
                  }
              }
          }
      }
      .advance-options {
          ul {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              padding: 0;
              .nav-item {
                  .nav-link {
                      color: var(--body-font-color);
                      border-bottom: 2px solid transparent;
                      background-color: transparent;
                      padding: 16px 57px;
                      text-decoration: unset;
                      @media (max-width:1855px) {
                          padding: 16px 45px;
                      }
                      @media (max-width:1663px) {
                          padding: 16px 30px;
                      }
                      @media (max-width:1423px) {
                          padding: 16px 28px;
                      }
                      &:active,
                      &.active {
                          border-bottom: 2px solid var(--theme-default);
                          color: var(--theme-default);
                      }
                  }
              }
          }
          .common-space {
              padding: 11px 0;
              p {
                  margin: 0;
                  color: rgba($theme-body-sub-title-color, 0.8);
                  [data-theme="dark"] & {
                      color: var(--light-font);
                  }
              }
              .header-top {
                  .btn {
                      padding: 6px 12px;
                      &:hover {
                          background-color: var(--theme-default);
                          i {
                              color: $white;
                          }
                      }
                  }
              }
          }
          .chats-user {
              border-bottom: unset;
              height: 575px;
              overflow-y: auto;
              li {
                  gap: 10px;
                  width: 100%;
                  border-bottom: 1px solid var(--chart-dashed-border);
                  cursor: pointer;
                  padding: 11px;
                  &:hover {
                      background-color: var(--light2);
                      [data-theme="dark"] & {
                          background-color: var(--body-color);
                      }
                  }
                  @media (max-width:1505px) {
                      flex-wrap: wrap;
                      gap: 1px;
                  }
                  &:last-child {
                      border-bottom: unset;
                  }
                  .chat-time {
                      display: flex;
                      gap: 10px;
                      align-items: center;
                      >div {
                          text-align: left;
                          [data-theme="dark"] & {
                              color: var(--light-font);
                          }
                      }
                  }
                  >div {
                      @media (max-width:1498px) {
                          width: 100%;
                          text-align: end;
                      }
                  }
              }
          }
      }
  }
  /**==common-profile==**/
  .active-profile {
      position: relative;
      img {
          width: 42px;
          height: 42px;
          @media (max-width:1650px) {
              width: 33px;
              height: 33px;
          }
      }
      .status {
          position: absolute;
          height: 14px;
          width: 14px;
          border-radius: 100%;
          bottom: 5%;
          right: -2px;
          border: 3px solid $white;
          @media (max-width:1600px) {
              width: 11px;
              height: 11px;
              border: 2px solid $white;
          }
      }
  }
  .contact-edit {
      i {
          color: rgba($theme-body-sub-title-color, 0.8);
          cursor: pointer;
          &::after {
              content: unset;
          }
          [data-theme="dark"] & {
              color: var(--body-font-color);
          }
      }
      svg {
          cursor: pointer;
          width: 18px;
          height: 18px;
          @media (max-width:360px) {
              width: 14px;
              height: 14px;
          }
          [data-theme="dark"] & {
              color: var(--body-font-color);
          }
      }
      .dropdown-menu {
          .dropdown-item {
              &:hover {
                  opacity: 1;
                  text-decoration: unset;
              }
          }
      }
      &.chat-alert {
          width: 40px;
          height: 40px;
          background: var(--light2);
          display: fke;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          [data-theme="dark"] & {
              background: var(--body-color);
          }
          @media (max-width:360px) {
              width: 32px;
              height: 32px;
          }
          @media (max-width:340px) {
              display: none;
          }
      }
  }
  .search-contacts {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .form-control {
          padding-inline: 35px;
          &:focus {
              box-shadow: unset;
              border-color: var(--chart-dashed-border);
          }
      }
      i {
          color: rgba($theme-body-sub-title-color, 0.8);
          width: 18px;
          height: 18px;
          right: 10px;
          top: 16px;
          position: absolute;
          [data-theme="dark"] & {
              color: var(--body-font-color);
          }
      }
      svg {
          stroke: rgba($theme-body-sub-title-color, 0.8);
          width: 18px;
          height: 18px;
          left: 10px;
          top: 13px;
          position: absolute;
          [data-theme="dark"] & {
              stroke: var(--body-font-color);
          }
      }
      .mic-search {
          right: 10px;
          top: 8px;
          position: absolute;
          left: unset;
      }
  }
  .contact-wrapper {
      padding-top: 14px;
      border-bottom: unset;
      height: 532px;
      overflow-y: auto;
      >p {
          line-height: 0;
          margin: 0;
          padding: 12px 0;
          font-size: 18px;
          font-weight: 500;
          color: var(--body-font-color);
      }
      ul {
          li {
              width: 100%;
              cursor: pointer;
              .chat-time {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  img {
                      width: 42px;
                      height: 42px;
                      @media (max-width:1650px) {
                          width: 33px;
                          height: 33px;
                      }
                  }
                  >div {
                      span {
                          color: var(--body-font-color);
                      }
                  }
              }
              svg {
                  width: 18px;
                  height: 18px;
              }
          }
          &:last-child {
              .common-space {
                  padding-bottom: unset;
              }
          }
      }
  }
  /**==rightpart==**/
  .right-sidebar-chat {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 0;
      border: 2px solid $white;
      border-left: unset;
      [data-theme="dark"] & {
          border: 2px solid var(--light-color);
          box-shadow: var(--card-box-shadow);
      }
      @include media-breakpoint-down(md) {
          border-radius: 10px;
      }
  }
  .right-sidebar-title {
      padding: 13px 25px;
      display: flex;
      justify-content: space-between;
      .common-space {
          width: 100%;
          .chat-time {
              display: flex;
              gap: 10px;
              align-items: center;
              [data-theme="dark"] & {
                  color: var(--body-font-color);
              }
              .active-profile {
                  img {
                      width: 42px;
                      height: 42px;
                      @media (max-width:360px) {
                          width: 36px;
                          height: 36px;
                      }
                  }
              }
              div {
                  p {
                      margin: 0;
                      color: rgba($theme-body-sub-title-color, 0.8);
                      [data-theme="dark"] & {
                          color: var(--body-font-color);
                      }
                  }
              }
              ul {
                  li {
                      img {
                          width: 42px;
                          height: 42px;
                      }
                  }
              }
              &.group-chat {
                  ul {
                      display: flex;
                      li {
                          img {
                              border: 3px solid transparent;
                              [data-theme="dark"] & {
                                  border-color: var(--solid-border);
                              }
                          }
                          +li {
                              margin-left: -12px;
                              img {
                                  border: 3px solid $white;
                              }
                          }
                          img {
                              width: 38px;
                              height: 38px;
                          }
                          .profile-count {
                              width: 38px;
                              height: 38px;
                          }
                          @media (min-width:768px) and (max-width: 1199px) {
                              &:nth-child(2),
                              &:nth-child(3),
                              &:nth-child(5),
                              &:nth-child(6) {
                                  display: none;
                              }
                          }
                          @media (min-width:341px) and (max-width: 410px) {
                              &:nth-child(2),
                              &:nth-child(3),
                              &:nth-child(5),
                              &:nth-child(6) {
                                  display: none;
                              }
                          }
                      }
                  }
                  >div {
                      @media (max-width:1045px) {
                          span,
                          p {
                              display: none;
                          }
                      }
                  }
              }
          }
      }
  }
  /**==common==**/
  .common-space {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  /**==chat==**/
  .msger-chat {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
      background: var(--chat-background);
      height: 620px;
      [data-theme="dark"] & {
          background: var(--body-color);
          box-shadow: var(--card-box-shadow);
      }
      &::-webkit-scrollbar {
          width: 6px;
      }
      &::-webkit-scrollbar-track {
          background: var(--photoswipe-border);
      }
      &::-webkit-scrollbar-thumb {
          background: var(--scrollbar-thumb);
      }
  }
  .msg {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      &:last-of-type {
          margin: 0;
      }
  }
  .msg-img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      background: var(--photoswipe-border);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
  }
  .msg-bubble {
      max-width: 450px;
      padding: 15px;
      border-radius: 15px;
  }
  .msg-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
  }
  .msg-info-name {
      margin-right: 10px;
      font-weight: bold;
  }
  .msg-info-time {
      font-size: 0.85em;
  }
  .left-msg {
      .msg-img {
          background-image: url(../../images/avatar/1.jpg);
          width: 33px;
          height: 33px;
          @media (max-width: 1200px) {
              min-width: 16px;
              min-height: 16px;
          }
      }
      .msg-bubble {
          position: relative;
          border-radius: 15px;
          background: $white;
          width: calc(100% - 33px);
          [data-theme="dark"] & {
              background: var(--card-color);
              color: var(--light-font);
          }
      }
  }
  .right-msg {
      flex-direction: row-reverse;
      .msg-bubble {
          color: $white;
          background: var(--theme-default);
          width: calc(100% - 33px);
      }
      .msg-img {
          margin: 0 0 0 10px;
          background-image: url(../../images/avatar/2.jpg);
          width: 33px;
          height: 33px;
          @media (max-width: 1200px) {
              min-width: 16px;
              min-height: 16px;
          }
      }
  }
  .msger-inputarea {
      display: flex;
      padding: 10px;
      border-top: 1px solid var(--chart-dashed-border);
      background: var(--chat-background);
      align-items: center;
      [data-theme="dark"] & {
          background: var(--card-color);
      }
      .dropdown-toggle {
          &::after {
              content: unset;
          }
          .dropdown-menu {
              inset: unset !important;
          }
      }
      .dropdown-form {
          position: relative;
          i {
              font-size: 16px;
              font-weight: 900;
              color: var(--theme-default);
              cursor: pointer;
              padding: 0;
          }
          .chat-icon {
              position: absolute;
              left: 0 !important;
              bottom: 22px !important;
              background: transparent;
              box-shadow: unset;
              div {
                  width: 47px;
                  height: 47px;
                  border-radius: 50%;
                  background-color: var(--white);
                  color: var($theme-body-font-color);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0;
                  opacity: 1;
                  svg {
                      width: 42px;
                      height: 42px;
                      cursor: pointer;
                  }
                  &:nth-child(1) {
                      svg {
                          stroke: var($theme-body-font-color);
                      }
                  }
                  &:nth-child(2) {
                      svg {
                          stroke: var($theme-body-font-color);
                          fill: none;
                      }
                  }
                  &:hover {
                      background-color: var(--theme-default);
                      opacity: 1;
                      &:nth-child(1) {
                          svg {
                              fill: $white;
                          }
                      }
                      &:nth-child(2) {
                          svg {
                              stroke: $white;
                          }
                      }
                  }
              }
          }
          .dropdown-item {
              border-radius: 15px;
              height: 50px;
              width: 50px;
          }
          svg {
              height: 24px;
              padding: 0;
              width: 24px;
          }
      }
      .msger-send-btn {
          i {
              color: $white;
          }
      }
      * {
          padding: 10px;
          border: none;
          border-radius: 3px;
          font-size: 1em;
      }
  }
  .msger-input {
      flex: 1;
      background: transparent;
      &:focus,
      &:focus-visible {
          outline: unset;
      }
  }
  .fg-emoji-container {
      left: calc(10px + (1405 - 10) * ((100vw - 320px) / (1920 - 320))) !important;
      @media (min-width:860px) and (max-width:945px) {
          left: 417px !important;
      }
      @media (min-width:686px) and (max-width:860px) {
          left: 360px !important;
      }
      @media (min-width: 588px) and (max-width:770px) {
          left: 200px !important;
      }
      @media (min-width:546px) and (max-width: 588px) {
          left: 144px !important;
      }
      @media (min-width:500px) and (max-width: 546px) {
          left: 88px !important;
      }
      @media (max-width:500px) {
          left: 22px !important;
          width: 262px !important;
      }
      .fg-emoji-nav {
          background-color: rgba($theme-body-sub-title-color, 0.8);
      }
      #fg-emoji-picker-move {
          display: none;
      }
  }
  .open-emoji {
      padding: 0;
      button {
          z-index: 0;
          position: relative;
          padding: 0 14px;
          background: transparent;
          &::before {
              content: "\f118";
              font-family: "FontAwesome";
              z-index: 1;
              position: relative;
              font-size: 20px;
              color: var(--text-gray);
          }
      }
  }
  .second-btn {
      z-index: 0;
      position: relative;
      padding: 0 14px;
      background: transparent;
      &::before {
          content: "\f118";
          font-family: "FontAwesome";
          z-index: 1;
          position: relative;
          font-size: 20px;
          color: var(--text-gray);
      }
  }
  .msger-send-btn {
      margin-left: 10px;
      background: var(--theme-default);
      width: 40px;
      height: 39px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.23s;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
          width: 1px;
          height: 12px;
      }
  }
  .custom-name {
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      @media (max-width:1650px) {
          width: 33px;
          height: 33px;
      }
  }
  .left-sidebar-wrapper {
      .advance-options {
          .chats-user {
              @include media-breakpoint-down(md) {
                  height: 200px;
              }
          }
      }
  }
  .messenger-send-btn {
      align-items: center;
      display: flex;
      font-weight: 700;
      height: 39px;
      justify-content: center;
      margin-left: 10px;
      transition: all .23s;
      width: 40px;
  }
  /**=====================
  14.  Chat CSS Ends
==========================**/